import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function UserList() {
  // const handleSubmit = (formData) => {
  //   console.log(formData);
  // };
  // const [FormOpen, setFormOpen] = useState(false);
  // const openForm = () => {
  //   setFormOpen(true);
  // };

  const Mov = useRef()

  const Navigate = useNavigate();
  const [Officer, setOfficer] = useState([]);

  // useEffect(() => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch("http://13.232.109.82/api/ExtensionOfficerList", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       //console.log(result)
  //       let resultArray = JSON.parse(result);
  //       setOfficer(resultArray.data.Registered_extension_officers);
  //     })
  //     .catch((error) => console.log("error", error));

  //   // axios.get("http://13.232.109.82/api/ExtensionOfficerList")
  //   // .then((response)=>{
  //   //   console.log(response)
  //   //   setOfficer(response.data.data)
  //   // })
  //   // .catch((error)=>{console.error('Error', error)})
  // }, []);

  const handleEdit = (user) => {
    const Data = user;
    Navigate("/edit", { state: Data });
    Navigate(0);
  };

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Officer.slice(indexOfFirstItem, indexOfLastItem);
    
  
    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    //console.log(currentPage)

  return (
    <>
      <div ref ={Mov} id="root"></div>
      
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row filter-box">
              <div className="row p-0 m-0 ">
                <div className="col-md-12">
                  <div className="card blur-box">
                    <div className="card-body ">
                      <div className="d-md-flex row align-items-center card-main-title-band pt-2 justify-content-between">
                        <div className="d-md-flex  align-items-center col-sm-12 col-md-5">
                          <div className="row align-items-center">
                            <h4 className="card-main-title pt-2 col-10">
                              User List
                            </h4>
                          </div>
                        </div>
                        {/* col-md-7 col-lg-6 col-xlg-3 m-2 mr-4 */}
                      </div>
                    </div>
                    <div >
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">User ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Class</th>
                            <th scope="col">School Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">E-Mail ID</th>
                            {/* <th scope="col">Address</th> */}
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((user) => (
                            <tr key={user.id}>
                              <td>{user.id}</td>
                              <td>{user.first_name + " " + user.last_name}</td>
                              <td>{user.phone}</td>
                              <td>{user.email}</td>
                              <td>{user.status}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleEdit(user)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      
                      <div>
                        <ul className="col justify-content-center text-center container">
                        <button class="btn btn-outline-primary" onClick={() =>{
                          if (currentPage > 1){
                            paginate(currentPage - 1)
                          }
                          Mov.current.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                        })
                          
                         } }> &#10094; Prev</button>
                          {" "}
                          {Array.from({ length: Math.ceil(Officer.length / itemsPerPage) }, (_, index) => (
                            <>
                            <button class="btn btn-outline-primary" key={index} onClick={() => {
                              paginate(index + 1)
                              Mov.current.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                            })
                              }}>
                              {index + 1} 
                            </button>
                            {" "}
                            </>
                          ))}
                          {" "}
                          <button class="btn btn-outline-primary" onClick={() => {
                            if(currentPage < Math.ceil(Officer.length / itemsPerPage)){
                              paginate(currentPage + 1)
                            }
                              Mov.current.scrollIntoView({
                              behavior: "smooth",
                              block: "end",
                          })
                          }}>Next &#10095;</button>
                        </ul>
                        <div className="text-center">
                          Page {currentPage} of {Math.ceil(Officer.length / itemsPerPage)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default UserList;