import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LogoImg from "./assets/learnEase.png";
import "./index.css"

function LoginPage(props) {
  const [EmailID, setEmail] = useState();
  const [Password, setPassword] = useState();
  

  const [NotLoggedIn, setNotLoggedIn] = useState(false);

  const [Error, setError] = useState();

  const Navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "email": EmailID,
      "password": Password
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/learnease_signin`, requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log((result))
        result = JSON.parse(result)
        // console.log((result.data[0].email))
        if(result.status===200){
          localStorage.setItem("email", result.data[0].email);
          localStorage.setItem("grade", result.data[0].class);
          localStorage.setItem("syllabus", result.data[0].syllabus);
          localStorage.setItem("username", result.data[0].name)
          alert('Login Successful')
          Navigate('/chat')
        }
        if(result.status===400){
          localStorage.setItem("email", "");
          localStorage.setItem("grade", "");
          localStorage.setItem("syllabus", "");
          localStorage.setItem("username", "")
          setNotLoggedIn(true)
          // console.log(result.errors)
          setError(result.errors)
        }
      })
      .catch(error => console.log('error', error));
  };

  var styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
    // background: "black",
    backgroundPosition: "right",
    backgroundImage: "url(https://img.freepik.com/free-vector/cute-artificial-intelligence-robot-isometric-icon_1284-63045.jpg?w=740&t=st=1707821000~exp=1707821600~hmac=9983b20e15698f5f737bb777c3efd18d2ecb26d545056e92a1453904f26abe72)",
    // backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const styles1 = {
    width: "350px",
    height: "450px",
    padding: "30px",
    // paddingTop: "75px",
    // background: "white", //"#2827276e",
    borderRadius: "20px",
  };

  return (
    <>
      
        <div style={styles}>
          <div style={styles1} className="card blur-box" >
            <div className="row">
                <img src={LogoImg} alt="Logo"/>
              <h2 className="text-center" style={{marginTop: "20px"}}> Login </h2>
              <div className="form-group col-md-10" style={{marginTop: "20px"}}>
                <label>Email ID</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email ID"
                  value={EmailID}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>
              <div className="form-group col-md-10" style={{marginTop: "20px"}}>
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Enter Password"
                  value={Password}
                  onChange={(p) => setPassword(p.target.value)}
                ></input>
              </div>
              <div className=" form-group text-center" style={{marginTop: "20px"}}>
                <button className="btn btn-primary" onClick={handleLogin}>
                  Login
                </button>
              </div>
              
              {NotLoggedIn && (
                <div class="form-group alert alert-warning" style={{marginTop: "10px"}} role="alert">
                  {Error}
                </div>
              )}
              <div className=" form-group text-center" style={{marginTop: "10px"}}>
                Don't have an account?
                <div>
                <a className="form-group text-center" href="/register">Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <footer className="text-center">
          <h5 className="">DEVELOPED AND MAINTAINED BY <br/> DEMETRIX INFOTECH PVT. LTD. <br/> @2024</h5>
        </footer>
        </>
  );
}

export default LoginPage;
