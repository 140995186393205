import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoImg from './assets/learnEase.png'

function RegistrationForm() {

  const Navigate = useNavigate();

  const [Grade, setGrade] = useState([]);
  const [DropdownSyllabus, setDropdownSyllabus] = useState([]);

  const [Error, setError] = useState();

  const [formData, setFormData] = useState({
    name: "",
    Class: "",
    phone: "",
    email: "",
    school: "",
    syllabus: "",
  });

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //console.log(e.target)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": formData.email,
      "class": formData.Class,
      "syllabus": formData.syllabus,
      "name": formData.name,
      "school_name": formData.school,
      "mobile_number": formData.phone
    })
    
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/learnease_signup`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        console.log(result);
        if(result.status===200){
          setError("Registered Successfully. Please check your registered Email for further updates");
          alert("Registered Successfully. Please check your registered Email for further updates"); 
        }
        else if(result.status===400){
          setError(result.errors); 
        }
        else{
          setError(result.errors);
        }
        // if (resultArray.status === 201) {
        //   setError("Registered Successfully");
        //   setTimeout(() => {
        //     Navigate(0);
        //   }, 2000);
        // } else {
        //   setError(resultArray.errors);
        // }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  

  useEffect(()=>{
    var requestOptions1 = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/learnease_dropdown`, requestOptions1)
      .then(response => response.text())
      .then(result => {console.log(result)
      var result = JSON.parse(result)
      // result = result.data[0].classes
      // console.log((result))
      
      // console.log(typeof(result))
      setGrade(result.data[0].classes)
      setDropdownSyllabus(result.data[0].syllabus)
      })
      
      .catch(error => console.log('error', error));
  
  }, []
  )
  
  return (
   <>
   <div style={{
    backgroundImage:"url(https://img.freepik.com/free-vector/cute-artificial-intelligence-robot-isometric-icon_1284-63045.jpg?w=740&t=st=1707821000~exp=1707821600~hmac=9983b20e15698f5f737bb777c3efd18d2ecb26d545056e92a1453904f26abe72)",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat"}}>
    <div
      class="container"
      style={{
        marginTop: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
        // height: "90vh",
        
      }}
    >
        
        
      <div className="card blur-box" style={{width:'500px', display:"flex", alignItems: "center",
        justifyContent: "center"}}>
        
        <div className="card-body ">
        <div className="text-center" style={{height:"50px", width:"200px"}}>
            <img  src={LogoImg} alt="Logo"/>
        </div>
        
          <h4 className="card-main-title">Registration Form</h4>
        </div>
        <div className="container">
          <form onSubmit={handleSubmit}>
            
              <div class="form-group" style={{margin:"10px"}}>
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            
            <div class="form-group" style={{margin:"10px"}}>
              <label>Phone Number</label>
              <input
                type="number"
                class="form-control"
                placeholder="Enter Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div class="form-group" style={{margin:"10px"}}>
              <label>Email address</label>
              <input
                type="email"
                class="form-control"
                placeholder="Enter Email ID"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div class="form-group" style={{margin:"10px"}}>
              <label>School Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter School Name"
                name="school"
                value={formData.school}
                onChange={handleInputChange}
              />
            </div>
            <div class="row" style={{margin:"0px"}}>
            <div class="form-group col-md-6" >
                <label>Class</label>
                <div>
                <select 
                name="Class"
                value={formData.Class}
                onChange={handleInputChange}
                required
            >
                <option value="" disabled>--select class--</option>
                {Grade.map((item) => (
                          <option key={item} value={item}> {item} </option>
                        ))}
            </select>
            </div>
              </div>
            <div class="form-group col-md-6" >
              <label>Syllabus</label>
              <div>
              <select
                name="syllabus"
                value={formData.syllabus}
                onChange={handleInputChange}
                required
            >
                <option value="" disabled>--select syllabus--</option>
                {
                  DropdownSyllabus.map((item)=>(
                    <option value={item}>{item}</option>
                  ))
                }
            </select>
            </div>  
            </div>
            </div>

            <div className=" form-group text-center" style={{margin:"20px"}}>
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
            {Error && (
              <div class="form-group alert alert-warning" role="alert">
                {Error}
              </div>
            )}
            <div className=" form-group text-center" style={{marginTop: "10px", marginBottom:"10px"}}>
                Already Registered User?
                <div>
                <a className="form-group text-center" href="/login">Login</a>
                </div>
              </div>
            
          </form>
        </div>
      </div>
    </div>
    </div>
    <footer className="text-center" style={{marginTop:"50px"}}>
          <h5 className="">DEVELOPED AND MAINTAINED BY <br/> DEMETRIX INFOTECH PVT. LTD. <br/> @2024</h5>
        </footer>
    </> 
  );
}

export default RegistrationForm;
