import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import LogoImg from "./assets/learnEase.png";
import LogoIco from "./assets/le-2.png";
import ChatIcon from "./assets/userChatIcon.png"
import { ReactComponent as MenuIcon } from "./assets/icons/menu_icon.svg";
import { ReactComponent as MenuClose } from "./assets/icons/menu_close_icon.svg";
import { ReactComponent as SendIcon } from "./assets/icons/send_icon.svg";

function ChatPage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [sessionId, setSessionId] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tag, setTag] = useState("");
  const [chatPairs, setChatPairs] = useState([]);
  const [localQuestions, setLocalQuestions] = useState([]);
  const [localAnswers, setLocalAnswers] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [inputQuestion, setInputQuestion] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const chatPairsRefs = useRef([]);

  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);

  const chatContainerRef = useRef(null);

  const username = localStorage.getItem('username');
  const grade = localStorage.getItem('grade');
  const syllabus = localStorage.getItem('syllabus');
  const email = localStorage.getItem('email');


  const { search } = useLocation();

  useEffect(() => {
    setCompanyName(username)
    
    setLogo(ChatIcon)
    setSessionId(new URLSearchParams(search).get("session"));
    const chatURL = `https://bot.xplorease.com/?session=${sessionId}`;

    const fetchData = async () => {
      setIsContentLoading(true);
      try {
        // API call for session validation
        await handleSessionValidation(chatURL);

        // API call for fetching suggested questions
        await handleSuggestedQuestions();
      } catch (error) {
        console.error("Error in API calls:", error);
      } finally {
        setIsContentLoading(false);
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  // useEffect(() => {
  //   const fetchSuggestedQuestions = async () => {
  //     setIsContentLoading(true);
  //     try{
  //       // API call for fetching suggested questions
  //       await handleSuggestedQuestions();
  //     } catch (error) {
  //       console.error("Error in API call:", error);
  //     } finally {
  //       setIsContentLoading(false);
  //     }
  //   };

  //   if (sessionId && localQuestions.length === 0) {
  //     // API call for suggested questions
  //     fetchSuggestedQuestions();
  //   }
  // }, [localQuestions]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleSessionValidation = async (chatURL) => {
    try {
      const response = await axios.post(
        "https://soliboat.solidaridadasia.com/chat_validation",
        {
          url: chatURL,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.success === true) {
          // setCompanyName(data.data[0].name);
          // setLogo(data.data[0].logo);
          // setTag(data.data[0].tag);
        } else {
          console.log("No Data Found!!");
        }
      }
    } catch (error) {
      console.error("Error in Session Validation:", error);
    }
  };

  // const handleFetchPreviousQuestions = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://soliboat.solidaridadasia.com/previous_questions",
  //       {
  //         session_id: sessionId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const data = response.data;
  //       if (data.success === true) {
  //         setPreviousQuestions((prevQuestions) => [
  //           ...prevQuestions,
  //           ...data.data[0].questions_list,
  //         ]);
  //         setPreviousAnswers((prevAnswers) => [
  //           ...prevAnswers,
  //           ...data.data[0].answers_list,
  //         ]);
  //       } else {
  //         console.log("No Data Found!!");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in Fetch Previous Questions:", error);
  //   }
  // };

  const handleSuggestedQuestions = async () => {
    try {
      const apiUrl = "https://soliboat.solidaridadasia.com/sample_questions";
      const response = await axios.post(
        apiUrl,
        {
          session_id: sessionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (
          responseData.success &&
          responseData.data &&
          responseData.data.length > 0
        ) {
          const suggestedQuestions = responseData.data[0].answer.split("\n");
          setSuggestedQuestions(suggestedQuestions);
        } else {
          console.error("Invalid response format:", responseData);
        }
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
    }
  };

  const handleInputChange = (event) => {
    setInputQuestion(event.target.value);
  };

  const scrollToQuestion = (index, offset = 44) => {
    if (chatPairsRefs[index]) {
      const element = chatPairsRefs[index];
      const scrollContainer = chatContainerRef.current;

      const currentScrollPosition = scrollContainer.scrollTop;

      const targetPosition = element.offsetTop - offset;

      const scrollDistance = targetPosition - currentScrollPosition;

      // Scroll to the target position
      scrollContainer.scrollBy({
        top: scrollDistance,
        behavior: "smooth",
      });

      if (window.innerWidth < 992) {
        setIsSidebarOpen(false);
      }

      setSelectedQuestionIndex(index);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
  
      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: 'smooth'
        });
      }, 10);
    }
  };

  // Scroll to the bottom after every render
  useEffect(() => {
    scrollToBottom();
  }, [localAnswers.length]);

  const handleSuggestedQuestionAnswer = async (suggestedQuestion) => {
    try {
      setIsAnswerLoading(true);
      setIsSendButtonDisabled(true);
      setLocalQuestions((prevQuestions) => [
        ...prevQuestions,
        suggestedQuestion,
      ]);

      const response = await axios.post(
        "https://soliboat.solidaridadasia.com/answer_question",
        {
          session_id: sessionId,
          question: suggestedQuestion,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.success === true) {
          setLocalAnswers((prevAnswers) => [
            ...prevAnswers,
            data.data[0].answer,
          ]);
        } else {
          setLocalQuestions((prevQuestions) => prevQuestions.slice(0, -1));
          console.log("No Response!");
        }
      }
    } catch (error) {
      console.error("Error in Question Answer:", error);
    } finally {
      setIsAnswerLoading(false);
      setIsSendButtonDisabled(false);
      scrollToBottom();
    }
  };

  const handleQuestionAnswer = async () => {

    scrollToBottom();

    try {
      setIsAnswerLoading(true);
      setIsSendButtonDisabled(true);
      setLocalQuestions((prevQuestions) => [...prevQuestions, inputQuestion]);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/learnease_answer_question`,
        {
          // session_id: sessionId,
          question: inputQuestion,
          email:email,
          subject: SelectedSub,
          class: grade,
          syllabus: syllabus
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.success === true) {
          setLocalAnswers((prevAnswers) => [
            ...prevAnswers,
            data.data[0].answer,
          ]);
          scrollToBottom();
        }
      }
    } catch (error) {
      alert(error.response.data.errors[0]);
      setLocalQuestions(localQuestions.slice(0, -1));
      setLocalAnswers(localAnswers.slice(0, -1));
      setChatPairs(chatPairs.slice(0, -1));
      console.error("Error in Question Answer:", error);
    } finally {
      setIsAnswerLoading(false);
      setIsSendButtonDisabled(false);
      setInputQuestion("");
      scrollToBottom();
    }
  };

  useEffect(() => {
    setChatPairs(
      localQuestions.map((question, index) => ({
        question,
        answer: localAnswers[index],
        ref: chatPairsRefs[index],
      }))
    );
  }, [localQuestions, localAnswers]);

  
  const[sub, setSub] = useState([]);
  const[SelectedSub, setSelectedSub] = useState('');
  const handleSub = (s)=>{
    setSelectedSub(s.target.value)
    
  }
  // console.log(SelectedSub)
  useEffect(()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/learnease_dropdown`, requestOptions)
      .then(response => response.text())
      .then(result => {console.log(result)
      var result = JSON.parse(result)
      result = result.data[0].subjects
      // console.log((result))
      
      // console.log(typeof(result))
      setSub(result)
      })
      
      .catch(error => console.log('error', error));
  },[]
  )
  // console.log((sub))

  return (
    <div className={"flex h-screen font-nunito bg-dark/10"}>
      {/* Sidebar */}
      <div
        className={`absolute md:relative ${
          isSidebarOpen
            ? "w-[90%] md:w-2/3 lg:w-1/4 2xl:w-1/5 transition-width ease-in-out duration-300"
            : "w-0 sm:w-8 transition-width ease-in-out duration-500"
        } bg-dark text-white`}
      >
        {/* NavBar Toggle */}
        <button
          className={`absolute top-3 md:top-10 ${
            isSidebarOpen ? "right-[-30px]" : "right-[-50px]"
          } md:right-[-30px] flex items-center justify-center w-10 h-10 text-black bg-neutral-200 rounded-xl focus:outline-none z-50`}
          onClick={toggleSidebar}
        >
          {!isSidebarOpen ? (
            <MenuIcon className="w-6 h-6" />
          ) : (
            <MenuClose className="w-6 h-6" />
          )}
        </button>

        {/* sidebar content starts here */}
        <div
          className={`flex flex-col justify-between w-full h-screen p-4 ${
            isSidebarOpen ? "" : "hidden"
          }`}
        >
          <div className="w-full flex flex-col overflow-y-scroll no-scrollbar">
            {/* XploreEase Logo & Title */}
            <div className="flex justify-center items-center mt-4 mx-2 p-3 bg-white/80 rounded-xl cursor-pointer">
              <img src={LogoImg} alt="Logo" className="h-8 md:h-10" />
            </div>

            {/* Border */}
            <div className="m-4 border-b border-neutral-400" />

            {/* Previous Questions List */}
            <div className="w-full flex flex-col mt-2 overflow-y-scroll no-scrollbar">
              <h2 className="text-neutral-500 font-semibold ml-4 mb-2">
                Previous Questions:
              </h2>
              {localQuestions.length === 0 && (
                <p className="font-semibold text-neutral-400 mx-1 p-3">
                  You have not asked any questions yet!
                </p>
              )}
              <ul>
                {localQuestions.map((question, index) => (
                    <li
                      key={index}
                      className="mx-1 mb-1 p-3 hover:bg-neutral-200/20 hover:rounded-xl cursor-pointer"
                      onClick={() => scrollToQuestion(index)}
                    >
                      <p className="text-white line-clamp-3" title={question}>
                        {question}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {/* Profile Details */}
          <div>
            <div className="m-4 border-b border-neutral-400" />
            <div
              className="min-h-[60px] relative bottom-0 flex items-center mt-4 mx-2 p-3 bg-neutral-200/20 rounded-xl cursor-pointer select-none"
              onClick={toggleProfile}
            >
              <img src={logo} alt="User_Pic" className="w-9 h-9 rounded-full" />
              <h5 className="ml-3 font-semibold">{companyName}</h5>

              <div
                className={`${
                  isProfileOpen ? "" : "hidden"
                } absolute -top-28 -left-4 w-full flex mt-4 ml-4 py-4 px-3 bg-white rounded-xl cursor-pointer`}
              >
                <img
                  src={logo}
                  alt="User_Pic"
                  className="w-9 h-9 2xl:w-12 2xl:h-12 rounded-full"
                />
                <div>
                  <h1 className="ml-2 xl:ml-3 text-sm xl:text-base  text-black font-semibold">
                    User:
                    <span className="pl-1 font-normal">{companyName}</span>
                  </h1>
                  <h1 className="ml-2 xl:ml-3 text-sm xl:text-base text-black font-semibold">
                    Grade:<span className="pl-1 font-normal">{grade}</span>
                  </h1>
                </div>
                
              </div>
             
            </div>
            <div>
            <a className="card text-center" style={{margin:"10px", width:"100px", height:"fit-content", borderColor:"black", borderStyle: "solid", borderWidth:"1px", backgroundColor: "rgb(229 229 229 / 0.2)", color:"white"}} href="/login" onClick={()=>{localStorage.setItem("email", "");
          localStorage.setItem("grade", "");
          localStorage.setItem("syllabus", "");
          localStorage.setItem("username", "")}}>
              Logout
            </a>
            </div>
            
          </div>
          
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`${
          isSidebarOpen
            ? "lg:w-3/4 xl:w-4/5 transition-width ease-in-out duration-300"
            : "w-full transition-width ease-in-out duration-300"
        } flex flex-col h-screen bg-slate-100`}
      >
        {/* Chat Window */}

        {/* Loading screen while API calls are in progress */}
        {isContentLoading && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-neutral-50 z-50">
            <div className="w-20 h-20 animate-spin">
              <div className="h-full w-full border-4 border-t-dark/50 border-b-dark/80 rounded-[50%]" />
            </div>
            <div className="text-sm lg:text-xl font-medium text-gray-500 mt-8 mx-8 lg:mx-0">
              Please wait while the content is loading{" "}
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
            </div>
          </div>
        )}

        {localQuestions.length === 0 ? (
          <div
            className={`${
              isSidebarOpen ? "hidden lg:flex" : ""
            } flex flex-col items-center justify-center w-full`}
            style={{ height: "calc(100% - 86px)" }}
          >           
            <div className="flex flex-col items-center justify-end h-3/5 xs:h-1/2 mb-4">
              <img
                src={LogoImg}
                alt="Logo"
                style={{height:"35px", width:"200px"}}
              />
              <h1 className="mt-4 lg:mt-6 text-center text-base md:text-xl lg:text-2xl font-semibold">
                <p>Welcome to LearnEase!</p>
                <p className="text-lg md:text-2xl lg:text-3xl font-normal">
                  What can I help you with?
                </p>
              </h1>
            </div>
            {/* Suggested Questions */}
            <div
              className={`${
                isSidebarOpen ? "w-3/4" : "w-[90%] md:w-4/5 xl:w-3/5"
              } h-2/5 xs:h-1/2 flex align-bottom mt-8 sm:mt-16`}
            >
              <div className="flex items-end mb-4">
                <div className="grid grid-cols-2 gap-1 sm:gap-2">
                  {suggestedQuestions.map((question, index) => (
                    <div
                      key={index}
                      className="mx-1 mb-1 p-2 xs:p-3 shadow rounded-lg sm:rounded-xl bg-white/20 hover:bg-dark/10 cursor-pointer"
                      onClick={() => {
                        handleSuggestedQuestionAnswer(question.substring(3));
                      }}
                    >
                      <p className="text-[10px] xs:text-xs sm:text-sm md:text-base text-black line-clamp-3">
                        {question}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            ref={chatContainerRef}
            className={`${
              isSidebarOpen ? "hidden lg:flex" : ""
            } relative flex flex-col items-center w-full overflow-y-scroll no-scrollbar`}
            style={{ height: "calc(100% - 84px)" }}
          >
            <div className="sticky top-0 w-full min-h-[40px] bg-white/90 z-40"></div>
            <div className="w-[90%] md:w-4/5 mt-4">
              {chatPairs.map((pair, index) => (
                <div key={index} className="w-full">
                  <div
                    className="relative w-full md:w-4/5 lg:2/3 flex justify-end md:justify-start md:bg-[#1F4172]/90 rounded-r-xl rounded-b-xl"
                    ref={(ref) => (chatPairsRefs[index] = ref)}
                  >
                    <img
                      src={logo}
                      alt="User_Pic"
                      className="absolute right-0 bottom-0 md:static w-6 h-6 md:min-w-[32px] md:w-8 md:h-8 lg:min-w-[40px] lg:w-10 lg:h-10 md:ml-2 md:my-3 md:p-1 bg-white rounded-full"
                    />
                    <p className="float-right mr-8 md:mr-0 font-nunito text-sm md:text-base bg-[#1F4172]/90 md:bg-[#1F4172]/0 text-white p-3 md:p-4 rounded-xl" style={{ width: 'calc(100% - 32px)', wordWrap: 'break-word' }}>
                      {pair.question}
                    </p>
                  </div>
                  <div className="relative w-full md:w-4/5 lg:2/3 float-right flex md:justify-between mt-3 mb-6 md:bg-white md:rounded-tr-none rounded-xl">
                    <img
                      src={LogoIco}
                      alt="Favicon"
                      className="md:hidden absolute bottom-0 w-6 h-6 md:p-1 bg-neutral-100 rounded-full"
                    />
                    <p className="ml-8 md:ml-0 font-nunito text-sm md:text-base bg-white text-black p-3 md:p-4 rounded-xl" style={{ width: 'calc(100% - 32px)', wordWrap: 'break-word' }}>
                      {/* Typing animation */}
                      {index === chatPairs.length - 1 && isAnswerLoading ? (
                        <span className="text-gray-500 mt-2">
                          Typing
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                        </span>
                      ) : (
                        pair.answer
                      )}
                    </p>
                    <img
                      src={LogoIco}
                      alt="Favicon"
                      className="hidden md:block md:min-w-fit w-8 lg:w-10 h-8 lg:h-10 mr-2 my-3 md:p-1 bg-neutral-100 rounded-full"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Input */}
        <div className="flex justify-center items-center w-full py-4 pb-5 bg-dark/10">
        <div
            className={`${
              isSidebarOpen
                ? "hidden lg:flex w-1/6"
                : "w-[30%] md:w-1/6 xl:w-1/6"
            } overflow-hidden flex items-center justify-center`}
          >

        <select className="rounded-lg  focus:outline border rounded-3xl bg-white shadow-[0_0_0_2px_rgba(255,255,255,0.95)] focus:shadow-[0_2px_6px_rgba(0,0,0,.05)]" style={{width: "90px",display:"flex"}} id="dropdown" value={SelectedSub} onChange={handleSub}>
              <option value="">
                -subject-
              </option>
              {sub.map((item) => (
                          <option className="dropdown p-1 col-md-10" key={item} value={item}> {item} </option>
                        ))}
              {/* <option value="English">
                English
              </option>
              <option value="Social-Science">
                Social Science
              </option>
              <option value="Science">
              Science
              </option>
              <option value="Mathematics">
              Mathematics
              </option> */}
              
            </select>
            </div>
          <div
            className={`${
              isSidebarOpen
                ? "hidden lg:flex w-3/4"
                : "w-[90%] md:w-4/5 xl:w-3/5"
            } overflow-hidden flex items-center justify-center border rounded-3xl bg-white shadow-[0_0_0_2px_rgba(255,255,255,0.95)] focus:shadow-[0_2px_6px_rgba(0,0,0,.05)]`}
          >
            
            
            
            <TextareaAutosize
              minRows={1}
              value={inputQuestion}
              onChange={handleInputChange}
              maxRows={5}
              placeholder="Message XploreEase Assistant"
              className="w-full py-3.5 pr-4 md:pr-12 pl-4 text-sm xs:text-base resize-none rounded-lg bg-transparent focus:outline-none placeholder:max-w-fit placeholder:text-sm xs:placeholder:text-base placeholder:line-clamp-1 placeholder:pt-[2px] xs:placeholder:pt-0 no-scrollbar"
            />
            <button
              className="w-8 h-8 p-1 mr-4 border border-black rounded-xl hover:scale-[102%] hover:shadow-one hover:bg-black/10 disabled:cursor-not-allowed disabled:scale-100 disabled:shadow-none"
              onClick={() => handleQuestionAnswer()}
              disabled={isSendButtonDisabled || inputQuestion === "" || SelectedSub===""}
            >
              <SendIcon className="w-6 h-6" fill="white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
